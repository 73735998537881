import { useEffect } from "react";
import styles from "./MonitoramentoCards.module.css";
import { useDashboard } from "../../../../context/DashboardContext";

export default function MonitoramentoCards() {
  const {
    populaCardsProtocolos,
    populaCardsTempoMedio,
    cardsProtocolo,
    // cardsTempoMedio,
  } = useDashboard();

  useEffect(() => {
    populaCardsProtocolos();
    populaCardsTempoMedio();
  }, []);

  return (
    <div className={styles.cards}>
      <div className={styles.card}>
        <div className={styles.card_cabecalho}>
          <span>Protocolos Em Tempo Real (Hoje)</span>
        </div>
        <div className={styles.card_corpo}>
          <div>
            <p>{cardsProtocolo.ura}</p>
            <p>Ura</p>
          </div>
          <div>
            <p>{cardsProtocolo.espera}</p>
            <p>Espera</p>
          </div>
          <div>
            <p>{cardsProtocolo.atendimento}</p>
            <p>Atendimento</p>
          </div>
          <div>
            <p>{cardsProtocolo.finalizado}</p>
            <p>Finalizado</p>
          </div>
        </div>
      </div>
      {/* <div className={styles.card}>
        <div className={styles.card_cabecalho}>
          <span>Atendentes</span>
        </div>
        <div className={styles.card_corpo}>
          <div>
            <p>{cardsTempoMedio.ta.media}</p>
            <p>Tempo Médio Atendimento</p>
          </div>
          <div>
            <p>{cardsTempoMedio.tr.media}</p>
            <p>Tempo Médio Resposta</p>
          </div>
          <div>
            <p>{cardsTempoMedio.te.media}</p>
            <p>Tempo Médio Espera</p>
          </div>
          <div>
            <p>{cardsProtocolo.online}</p>
            <p>Atendentes Online</p>
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.card_corpo}>
          <div>
            <p>{cardsTempoMedio.ta.maxTA}</p>
            <p>Maior Tempo Atendimento</p>
          </div>
          <div>
            <p>{cardsTempoMedio.ta.minTA}</p>
            <p>Menor Tempo Atendimento</p>
          </div>
          <div>
            <p>{cardsTempoMedio.te.maxTE}</p>
            <p>Maior Tempo Espera</p>
          </div>
          <div>
            <p>{cardsTempoMedio.te.minTE}</p>
            <p>Menor Tempo Espera</p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
